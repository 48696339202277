@import 'assets/scss';
.boxGroupInput {
    display: block;
}

.label-container {
    display: flex;
    justify-content: space-between;
}

.formGroup {
    border-radius: var(--radius-input);
    position: relative;
    display: flex;
    align-items: center;
}

.errorMsg {
    border-color: $color-red1;
}

.messageError {
    margin: 5px 0px 0 0px;
    // background-color: $color-white;
    .text-err {
        color: $color-red1;
        font-size: $text-small;
        font-family: $font-regular;
    }
}

.errMsg {
    border: 1px solid var(--red-color-1);
    position: relative;
    animation: shake 0.9s ease 1;
}

.ic_error {
    position: absolute;
    right: 10px;
    top: 6px;
}

.icon-right {
    position: absolute;
    right: 10%;
    top: 25%;
}

.label {
    font-family: var(--bs-body-font-family-SanFranciscoText-Regular);
    font-size: $text-small;
    padding-bottom: 4px;
    span {
        color: $color-dark-red
    }
}

.label-right-text {
    color: $color-green-header;
    font-size: $text-small;
    font-family: $font-regular;
    cursor: pointer;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-1px);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(1px);
    }
}

.select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    border: none;
}

.disable-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    background-color: $color-gray-e6;
    border: 1px solid $color-gray-d8;
}

.focus-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    border: none;
}

.error-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // border: 0.5px solid $color-red;
    border-radius: 5px;
    font-size: $text-small;
    font-family: $font-regular;
    animation: shake 0.9s ease 1;
}

.text-error-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $color-red;
    font-size: $text-small;
    font-family: $font-regular;
}

input[type=date]::-webkit-calendar-picker-indicator {
    // background: url(../../assets/image/Img_Calendar.png) no-repeat;
    cursor: pointer;
}

input[type=date]::before {
    color: $color-gray4;
    font-family: $font-regular;
    font-size: $text-small;
    content: attr(placeholder);
}

input[type=date] {
    color: $color-white;
}

input[type=date]:focus,
input[type=date]:valid {
    color: $color-gray2;
}

input[type=date]:focus::before,
input[type=date]:valid::before {
    content: '' !important;
}

::placeholder {
    color: $color-gray4;
    font-family: $font-regular;
    font-size: $text-small;
    text-transform: none;
}

.input-style {
    color: $color-gray2;
    font-family: $font-regular;
    font-size: $text-small;
    display: flex;
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    margin-top: 5px;
    border: solid 0.5px #dbd7d7;
}

input[autoCapitalize=characters] {
    text-transform: uppercase;
}

input[autoCapitalize=words] {
    text-transform: capitalize;
}

input[autoCapitalize=none] {
    text-transform: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    // Chrome, Safari, Edge, Opera delete right arrow
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    // firefox delete right arrow
    -moz-appearance: textfield;
}