$text-tiny: 10px;
$text-x-small: 12px;
$text-small: 14px;
$text-normal: 16px;
$text-x-normal: 18px;
$text-big: 20px;
$text-22: 22px;
$text-24: 24px;
$text-26: 26px;
$text-28: 28px;
$text-30: 30px;
$text-32: 32px;
$text-34: 34px;
$text-36: 36px;
$text-40: 40px;