@import 'assets/scss';
.boxGroupInput {
    display: block;
}

.formGroup {
    border-radius: var(--radius-input);
    position: relative;
    display: flex;
    align-items: center;
}

.errorMsg {
    border-color: $color-red1;
}

.messageError {
    margin-top: 5px;
    margin-bottom: -10px;
    background-color: $color-white;
    .text-err {
        color: $color-red1;
        font-size: $text-small;
        font-family: $font-regular;
    }
}

.errMsg {
    border: 1px solid var(--red-color-1);
    position: relative;
    animation: shake 0.9s ease 1;
}

.ic_error {
    position: absolute;
    right: 10px;
    top: 6px;
}

.icon-right {
    position: absolute;
    right: 10%;
    top: 25%;
}

.label {
    font-family: var(--bs-body-font-family-SanFranciscoText-Regular);
    font-size: $text-small;
    padding-bottom: 4px;
    span {
        color: $color-dark-red
    }
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-1px);
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(1px);
    }
}

.select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    border: none;
}

.disable-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid $color-gray-d8;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    background-color: $color-gray-e6;
    border: 1px solid $color-gray-d8;
}

.focus-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    border: solid 0.5px #dbd7d7;
}

.error-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 0.5px solid $color-red;
    border-radius: 5px;
    color: $color-gray2;
    font-size: $text-small;
    font-family: $font-regular;
    animation: shake 0.9s ease 1;
}

.text-error-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $color-red;
    font-size: $text-small;
    font-family: $font-regular;
}

::placeholder {
    color: $color-gray4;
    font-family: $font-regular;
    font-size: $text-small;
}

.input-style {
    display: flex;
    padding: 16px 22px;
    resize: none;
    border: 1px solid $color-gray-d8;
    color: $color-gray2;
    font-family: $font-regular;
    font-size: $text-small;
    border-radius: 5px;
    width: 100%;
    height: 100px;
    border: solid 0.5px #dbd7d7;
    width: 100%;
}