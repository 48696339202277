@import 'assets/scss';

.text {
    font-family: $font-regular;
    text-align: center;
    font-size: $text-normal;
    display: block;
}

.btn_text {
    font-family: $font-regular;
    text-align: center;
    font-size: $text-small;
    display: block;
    min-width: 80px;
    font-weight: 600;
}

.container {
    border-radius: 5px;
    border: unset;
    padding: 10px 0;
}

.btn-base {
    border: none;
    box-shadow: none;
    color: $color-white;
    padding: 8px 16px;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 5px;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.5;
    }
}

.btn {
    &_red {
        @extend .btn-base;
        background-color: $color-red;
    }

    &_blue {
        @extend .btn-base;
        background-color: $color-blue
    }

    &_gray {
        @extend .btn-base;
        background-color: $color-gray
    }

    &_green {
        @extend .btn-base;
        background: $color-blue-opacity;
        border: 1px solid $boder-blue-transparent;
    }

    &_green_white {
        @extend .btn-base;
        background-color: $color-white;
        border: solid $color-green;
        border-width: 1px;
    }

    &_linear {
        @extend .btn-base;
        background: $color-linear-red;
    }
    &_linear_green {
        @extend .btn-base;
        background: $color-linear-green;
        text-align: 'center'
    }
}

.btn_txt {
    &_white {
        @extend .btn_text;
        color: $color-white;
    }

    &_green {
        @extend .btn_text;
        color: $color-green;
    }

    &_black {
        @extend .btn_text;
        color: $color-gray3;
    }
}

.icon-right-styles {
    width: 20px;
    height: 20px;
}

.autocenter{
    margin: 20px auto;
}