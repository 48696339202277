@import './colors';
@import './fonts';
@import './config';

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html {
    overflow: auto;
}

body {
    font-family: $font-regular;
    font-size: $text-normal;
    color: $color-black-origin;
    overflow: hidden;
}

.wrap_header {
    background: url('../icon/bg_banner.png') no-repeat;
    background-size: cover;
}

.menu-bar--navigation {

    opacity: 1;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    .menu-left {
        display: flex;
        align-items: center;
        gap: 20px;

        .menu .nav-menu {
            display: flex;
            gap: 30px;
            list-style: none;
            font-size: $text-x-normal;

            .nav-item {
                padding: 0 5px;

                a {
                    text-decoration: none;
                    color: $color-black-origin;

                    svg {
                        font-size: $text-small;
                        margin-left: 10px;
                    }
                }

                a.active {
                    font-weight: 600;
                }
            }
        }

        .logo {
            max-width: 150px;

            img {
                width: 100%;
            }
        }
    }

    .menu-right {
        display: flex;
        align-items: center;
        gap: 10px;

        .button_sellect {
            display: flex;

            .button_style {
                text-decoration: none;
                color: $color-black-origin;
                font-size: $text-small;
                font-weight: 600;
                border: 1px solid $boder-blue-transparent;
                background: $color-blue-opacity;
                padding: 10px 20px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .sellect-language .select_language {
            outline: none;
            font-size: $text-small;
            background: $color-blue-opacity;
            border: 1px solid $boder-blue-transparent;
            padding: 8px 16px;
            border-radius: 50px;
            font-weight: 600;
            font-family: $font-regular;
        }
    }
}

.wrap_box_slide {
    padding-top: 50px;
    padding-bottom: 50px;

    .slide_box_left {
        max-width: 85%;

        .title_web {
            font-weight: 600;
            font-size: $text-34;
            line-height: 47px;
            color: $color-gray3;
            margin-top: 0;
        }

        .descreption {
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            color: $color-gray3;
            font-size: $text-24;
            text-transform: capitalize;
            margin: 10px 0;
        }
    }
}

.button {
    display: flex;
    gap: 20px;
}

.content {
    overflow: hidden;
    color: $color-gray3;

    & p {
        font-style: normal;
        font-weight: 400;
        font-size: $text-x-normal;
        line-height: 21px;
        font-family: $font-regular;
    }

    & ul {
        list-style: none;
        padding-left: 0;
        font-family: $font-regular;
        font-size: $text-x-normal;

        & li {
            display: flex;
            gap: 10px;
            padding: 5px 0;
        }
    }
}

.section_wrap_box_business {
    background: url('../icon/bg_banner_sec.png') no-repeat;
    background-size: cover;
    padding: 30px 0;
}

.wrap_box_style_cover {
    overflow: hidden;

    & .head {
        margin-bottom: 40px;

        & h2 {
            text-align: center;
            font-size: $text-32;
            line-height: 38px;
            margin-top: 0;
        }

        & p {
            width: 50%;
            margin: auto;
            text-align: center;
        }

        @media (max-width: 1023px) {
            & p {
                width: 100%;
            }
        }
    }

    & .descreption {
        margin-bottom: 20px;
    }
}


.item_list_worked {
    margin: 30px 0;

    .item {
        overflow: hidden;
        padding: 0 0px 0px;
        border: 1px solid $color-gray-e9;
        box-shadow: 0px 0px 5px 5px $color-tras-black;
        border-radius: 16px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        background: $color-white;
        cursor: pointer;

        & .box-image {
            position: relative;

            & img.image {
                height: 400px;
                object-fit: cover;
                width: 100%;
            }

            & .button_on-abs {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: none;
                align-items: center;
                justify-content: center;
            }
        }

        & .box_wrapper_content {
            background: $color-gray-e8;
            padding: 15px 20px 20px;

            & h3 {
                margin-top: 0;
            }

            & p {
                font-size: 14px;
                margin-bottom: 0;

                @media (min-width: 768px) {
                    font-size: 14px;
                    margin-bottom: 0;
                    height: 120px;
                }
            }
        }

        &:hover .button_on-abs {
            display: flex;
        }

        &:hover .box_wrapper_content {
            background: $color-linear-green;

            & h3,
            p {
                color: $color-white;
            }
        }
    }
}

.wrapper_bridge_idea {
    background: $color-gray-e8;
    padding: 40px 0;

    .list_square_box {
        padding-top: 30px;

        & .square_box {
            overflow: hidden;
            text-align: center;

            & p {
                font-style: normal;
                font-weight: 500;
                font-size: $text-big;
                line-height: 23px;
                color: $color-purple;
                margin-top: 0;
            }

            & .square {
                background: $color-white;
                padding: 50px 25px;
                text-align: center;
                border-radius: 16px;
                min-height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;

                & p {
                    margin: 0;
                    font-style: normal;
                    font-weight: 600;
                    font-size: $text-32;
                    line-height: 38px;
                }
            }
        }
    }

}

.wrapper_choose-us0 {

    background: url('../icon/bg_why_sec.png') no-repeat;
    background-size: cover;
    padding: 40px 0;

    & .wrapper_choose_box_left {
        overflow: hidden;
        text-align: center;

        & img {
            max-width: 400px;
        }
    }

    & .wrapper_choose_box_right {
        padding-top: 30px;

        .button {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}

.wrapper_box_solution {
    background: url('../icon/bg_solution_sec.png') no-repeat;
    background-size: cover;
    padding: 50px;

    .solution_business_item {
        padding-bottom: 30px;

        & .top,
        .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 20px;
            text-align: center;
            margin-top: 20px;
        }

        & .item_solution {
            overflow: hidden;
            max-width: 200px;
            width: 100%;

            & .item_icon {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                & .icon_bg {
                    background: linear-gradient(0deg, #FF0000 6.21%, #0038FD 102%);
                    height: 70px;
                    width: 70px;
                    border-radius: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .icon {
                        height: 40px;
                        width: 40px;
                    }
                }
            }

            & .text {
                min-height: 38px;
            }
        }
    }

    & .video_box_solutions {
        margin: 30px 15% -300px 15%;
        align-content: center;
    }
}

.wrapper_idea_reality {
    margin-top: 280px;
    padding: 40px 0;
}

.slide {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.slide-container {
    display: flex;
    transition: transform 0.5s ease;
}

.slide-item {
    flex: 0 0 25%;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
    opacity: 0.5;
    transition: opacity 0.3s ease, transform 0.5s ease;
    position: relative;
    margin-bottom: 50px;

    & .icon {
        position: absolute;
        right: -15%;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;

        & .icon_Round {
            border: 1px solid $color-gray-d8;
            border-radius: 50px;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $text-small;

            &.active {
                background: $color-linear-green;
                border: none;

                & svg {
                    fill: $color-white;
                }
            }
        }
    }

    & img {
        max-width: 270px;
        max-height: 250px;
        transform: scale(0.7);
        width: 100%;
        height: 100vh;
    }

    & h4 {
        margin-top: 0;
    }
}

.slide-item.active {
    opacity: 1;
    transform: scale(1.2);

    & img {
        max-width: 350px;
        max-height: 300px;
        transform: scale(1);
        width: 100%;
        height: 100vh;
    }
}

.slide-item:first-child {
    opacity: 0.7;
    transform: translateX(-50%) scale(0.9);
}

.slide-item:nth-child(3),
.slide-item:nth-child(4) {
    opacity: 0.7;
    transform: translateX(50%) scale(0.9);
}

.slide-item.animate {
    animation: slideAnimation 0.5s ease;
}

@keyframes slideAnimation {
    from {
        transform: translateX(50%) scale(0.9);
    }

    to {
        transform: translateX(0%) scale(1);
    }
}

.wrapper_box_feedback {
    background: $color-gray-e9;
    padding: 40px 0;

    & .item {
        padding: 0 15px;

        & .item_slider {
            background: $color-white;
            border: 1px solid $color-gray5;
            border-radius: 8px;
            padding: 15px;



            & .image {
                text-align: center;

                & img {
                    margin: 0 auto 25px;
                }
            }

            & .descreptions {
                font-size: $text-small;
                line-height: 30px;
            }

            & .job_custommer {
                text-align: center;

                & h4 {
                    margin-top: 5px;
                    color: $color-gray2;
                    font-size: $text-big;
                    font-weight: normal;
                }

                & span {
                    color: $color-gray2;
                    font-size: $text-small;
                }
            }
        }
    }
}

.wrapper_box_team {
    padding-bottom: 40px;
    padding-top: 40px;

    .list_show_team {
        padding: 0;

        .item_person {
            padding: 30px 15px 0;
            position: relative;
            text-align: center;
            cursor: pointer;

            & .box_effect_image {
                padding-bottom: 30px;
                display: flex;
                justify-content: center;

                & .border-box-around {
                    position: relative;

                    & .social_link_customer {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        gap: 30px;
                        background: $color-linear-black;
                        border-radius: 50%;

                        & img {
                            width: 25px;
                            height: 25px;
                            object-fit: contain;
                        }
                    }
                }

                & img {
                    margin: auto;
                    max-width: 260px;
                    max-height: 260px;
                    width: 100%;
                    height: 100vh;
                }

                &::after {
                    content: " ";
                    display: block;
                    width: 300px;
                    height: 300px;
                    margin: 8px;
                    border-radius: 50%;
                    border-width: 7px;
                    border-style: solid;
                    border-image: #4299E1;
                    border-color: #4299E1 transparent #4299E1 transparent;
                    top: -2px;
                    position: absolute;
                    right: 18px;
                    left: 27px;
                    transform: rotate(40deg);
                }

                &:hover::after {
                    animation: lds-dual-ring 2s linear infinite;
                }

                &:hover .social_link_customer {
                    display: flex;
                }
            }

            & .position_job {
                text-align: center;

                & h4,
                span {
                    margin-bottom: 0;
                    color: $color-gray2;
                }
            }
        }
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(40deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .item_partner {

        padding: 0 15px;
        overflow: hidden;

        & img {
            max-width: 200px;
            max-height: 129px;
            height: 100vh;
            margin: auto;
            width: 100%;
            object-fit: contain;
        }
    }
}


.slick-track {
    display: flex;
}

.form_wrappper {
    background: url('../icon/bg_form.png') no-repeat;
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 50px;

    & .form_wrapper_input {
        max-width: 550px;
        margin: 0 auto;

        & .dowloadFile {
            background: $color-white;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .text_file_dowload h3 {
                margin-bottom: 0;
                margin-top: 0;
            }

            & .text_file_dowload p {
                margin-bottom: 0;
                margin-top: 5px;
            }

            & .file {
                cursor: pointer;

                & input {
                    display: none;
                }
            }
        }

        & .box_input_group {
            position: relative;

            & .title {
                font-style: normal;
                font-weight: 600;
                font-size: $text-normal;
                line-height: 19px;
                display: block;
                margin-bottom: 20px;
            }

            & .undefined {
                margin-bottom: 20px;
            }
        }
    }
}

textarea.input-style {
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
    border: solid 0.5px #dbd7d7;
    padding: 15px;
}


.input_file_box {
    font-size: 14px;
    display: flex;
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    border: none;
    background: $color-white;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & .input_file {
        display: none;
    }

    & label {
        display: flex;
        color: $color-gray4;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
    }
}

.footerContainer {
    background: url('../icon/bg_banner_sec.png') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0 30px;

    & .logo {
        max-width: 200px;
        width: 100%;
    }

    & .info_company_us {
        position: relative;
        overflow: hidden;

        & .name_company {
            margin: 15px 0;

            & img {
                width: 100px;
            }

            & h3 {
                margin: 0 0 5px;
                color: $color-blue;
            }

            & p {
                margin: 0 0 10px;
                color: $color-blue;
            }
        }

        & .contact_flow {
            position: relative;

            & ul {
                padding-left: 0;
                list-style: none;
                position: relative;

                & li {
                    display: flex;
                    align-items: center;
                    padding: 7px 0;

                    & img {
                        margin-right: 10px;
                    }

                    & a {
                        color: $color-black-origin;
                        text-decoration: none;
                    }
                }
            }
        }

        & .social {
            & .list_social {
                display: flex;
                gap: 20px;
                margin: 10px 0;

                & img {
                    width: 45px;
                    height: 45px;
                }
            }

            & .confirm_img_business {
                display: flex;
                gap: 20px;
                align-items: start;
                flex-direction: column;

                & img:first-child {
                    max-width: 200px;
                    width: 100%;
                }
            }
        }
    }

    & .menu_direct {
        & ul {
            padding-inline-start: 18px;

            li {
                padding: 7px 0;

                a {
                    color: $color-black-origin;
                    text-align: left;
                    text-decoration: none;
                    transition: all 1s ease;

                    &:hover {
                        color: $color-blue;
                        margin-left: 10px;
                        transition: all 1s ease;
                    }
                }
            }
        }
    }

    & .coppy_right {
        text-align: center;
        margin-top: 30px;
    }
}

.descreption p {
    display: flex;
    list-style: none;
    justify-content: center;
    font-size: 12px;
}

.swapper_slider-box .item_slide img {
    width: 100%;
}

.modal_get_started {
    & .ant-modal-title {
        text-align: center;
        font-size: $text-22;
        margin-bottom: 20px;
    }
}

.inputStyle {
    border: 1px solid $color-gray-d8;
    width: 100%;
    border-radius: 5px;
    padding: 10px 7px;
    color: $color-black-origin;
    font-size: $text-small;

    &::placeholder {
        color: $color-black-origin;
        font-size: $text-small;
    }
}

.inputStyle:focus {
    outline: unset;
}

.name_phone_code {
    display: flex;
    gap: 5px;
}

.name_phone_code select {
    width: 80px;
}

.name_phone_code select:focus {
    outline: none;
    border: none;
}

.name_phone_code select:focus-visible {
    outline: none;
    border: 1px solid $color-gray-d8;
}

.ant-modal-body .col-xs-12.col-sm-6.col-md-6 {
    margin-bottom: 10px;
    height: 40px;
}

.sellect_option_form {
    border: 1px solid $color-gray-d8;
    width: 100%;
    border-radius: 5px;
    padding: 10px 7px;
    color: $color-black-origin;
}

@media (min-width: 1301px) and (max-width: 1500px) {
    .wrapper_box_team .list_show_team .item_person .box_effect_image::after {
        top: -3px;
        right: 0;
        left: -18px;
        transform: rotate(20deg);
    }

    .menu-bar--navigation .menu-left .menu .nav-menu .nav-item {
        padding: 0 0;
    }
}

@media(min-width: 992px) and (max-width: 1300px) {
    .wrapper_box_team .list_show_team .item_person .box_effect_image::after {
        display: none;
    }

    .menu-bar--navigation .menu-left .menu .nav-menu .nav-item a {
        font-size: 15px;
    }
}

@media(min-width: 992px) and (max-width: 1200px) {
    .menu-bar--navigation .menu-left .menu .nav-menu {
        gap: 10px;
    }
}

@media(max-width: 991px) {

    body {
        overflow-y: hidden;
    }

    #root {
        overflow: hidden;
    }

    .header-container {
        position: fixed;
        z-index: 99;
        background: $color-white;
        width: 100%;
        top: 0;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    }

    .wrap_header {
        background: $color-white;
    }

    .menu-bar--navigation .menu-left {
        justify-content: space-between;
        width: 100%;
    }

    .navbar-button svg {
        font-size: $text-big;
    }

    .menu.actve {
        position: fixed;
        width: 100%;
        background: #fff;
        height: 100vh;
        z-index: 999;
        top: 94px;
        left: 0;
        display: block;
    }

    .menu.disable {
        display: none;
    }

    .menu-bar--navigation .menu-left .menu .nav-menu {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    .menu-bar--navigation .menu-left .menu .nav-menu .nav-item {
        display: block;
        padding: 10px 0;
    }

    .menu-bar--navigation .menu-left .menu .nav-menu .nav-item a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu-bar--navigation .menu-right {
        position: fixed;
        top: 50vh;
        z-index: 999;
        flex-direction: column;
        width: 92%;
        text-align: center;

    }

    .menu-right.active {
        display: block;
    }

    .menu-right.disable {
        display: none;
    }

    .menu-bar--navigation .menu-right .button_sellect {
        flex-direction: column;
        width: 100%;
    }

    .menu-bar--navigation .menu-right .button_sellect button {
        width: 100%;
        justify-content: center;
    }

    .swapper_slider-box .item_slide img {
        width: 100%;
    }

    .wrapper_bridge_idea .list_square_box .square_box {
        margin-bottom: 20px;
    }

    .button,
    .wrapper_choose-us0 .wrapper_choose_box_right .button {
        display: block;
    }

    .wrapper_box_solution .solution_business_item .item_solution {

        width: 25%;
        float: left;
        padding: 0 7.5px;
    }

    .wrapper_box_solution .solution_business_item .top,
    .wrapper_box_solution .solution_business_item .bottom {
        display: block;
        margin: 0 -7.5px;
    }

    .wrapper_idea_reality {
        margin-top: 220px;
    }

    .wrap_box_slide {
        background: url('../icon/bg_banner.png') no-repeat;
        background-size: cover;
        padding-bottom: 10px;
        margin-top: 94px;
    }

    .wrapper_box_solution {
        padding: 50px 0;
    }

    .wrapper_choose-us0 .wrapper_choose_box_left img {
        max-width: 100%;
    }
}

@media(max-width: 767px) {

    .swapper_slider-box .item_slide img {
        width: 100%;
    }

    .wrap_box_slide .slide_box_left .title_web {
        font-size: $text-30;
        line-height: 38px;
    }

    .wrap_box_slide .slide_box_left .descreption,
    .content p,
    .content ul li {
        font-size: $text-normal;
    }

    .wrap_box_style_cover .head {
        margin-bottom: 0;
    }

    .wrap_box_style_cover .head h2 {
        font-size: $text-22;
    }

    .section_wrap_box_business {
        padding: 0;
    }

    .item_list_worked {
        margin-bottom: 0;
    }

    .wrapper_bridge_idea .list_square_box {
        padding-top: 0;
    }

    .wrapper_bridge_idea .list_square_box .square_box {
        margin-bottom: 20px;
    }

    .wrapper_choose-us0 .wrapper_choose_box_right .button {
        flex-direction: column;
        align-items: flex-start;
    }

    .slide {
        width: 100%;
        display: block;
    }

    .slide-container {
        display: block;
        margin-top: 30px;
        overflow: hidden;
    }

    .slide-item,
    .slide-item .icon {

        display: none
    }

    .slide-item.active {
        display: block;
    }

    .wrapper_box_solution {
        padding: 20px 0 60px;
    }

    .button {
        display: block;
    }

    .wrapper_box_solution .solution_business_item .top,
    .wrapper_box_solution .solution_business_item .bottom {
        display: block;
        margin: 0 -7.5px;
    }

    .wrapper_box_solution .solution_business_item .item_solution {
        width: 50%;
        float: left;
        padding: 0 7.5px;
    }

    .wrapper_idea_reality {
        margin-top: 200px;
    }

    .wrapper_box_feedback {
        padding: 0px 0 20px;
    }

    .form_wrappper {
        padding-top: 0;
        padding-bottom: 20px;
    }

    .wrapper_choose-us0 .wrapper_choose_box_right {
        padding-top: 0;
    }

    .wrapper_choose_box_right h2 {
        margin-top: 0;
    }

    .wrap_box_slide .slide_box_left {
        max-width: 100%;
    }

    .wrapper_box_team .list_show_team .item_person .box_effect_image::after {
        top: -4px;
        position: absolute;
        left: 11px;
        transform: rotate(34deg);
    }
}

.fadeIn1 {
    animation-name: fadeIn1;
    -webkit-animation-name: fadeIn1;

    animation-duration: 5s;
    -webkit-animation-duration: 5s;

    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;

    visibility: visible !important;
}

@keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    20%,
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    20%,
    100% {
        opacity: 1;
    }
}

.fadeIn2 {
    animation-name: fadeIn2;
    -webkit-animation-name: fadeIn2;

    animation-duration: 5s;
    -webkit-animation-duration: 5s;

    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;

    visibility: visible !important;
}

@keyframes fadeIn2 {

    0%,
    20% {
        opacity: 0;
    }

    40%,
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn2 {

    0%,
    20% {
        opacity: 0;
    }

    40%,
    100% {
        opacity: 1;
    }
}

.fadeIn3 {
    animation-name: fadeIn3;
    -webkit-animation-name: fadeIn3;

    animation-duration: 5s;
    -webkit-animation-duration: 5s;

    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;

    visibility: visible !important;
}

.animate__fadeInLeft_phone {
    -webkit-animation: fadeInLeftPhone;
    animation: fadeInLeftPhone;
    animation-duration: 4s;
}

@keyframes fadeIn3 {

    0%,
    40% {
        opacity: 0;
    }

    60%,
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn3 {

    0%,
    40% {
        opacity: 0;
    }

    60%,
    100% {
        opacity: 1;
    }
}

@keyframes fadeInLeftPhone {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
}

.page-padding {
    padding: 0 15%;

    @media(max-width: 991px) {
        padding: 20% 5% 0 5%;
    }
}

.content-padding {
    padding: 0 15%;

    @media(max-width: 991px) {
        padding: 0% 5%;
    }
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.center-column {
    display: flex;
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    max-width: 150px;

    img {
        width: 100%;
    }
}

.full-image {
    width: 65%;

    @media(max-width: 991px) {
        width: 95%;
    }
}

.h5 {
    font-size: 33px;
}

.h6 {
    font-size: 27px;
    color: white;
}

.h7 {
    font-size: 23px;
    color: white;
}

.h8 {
    font-size: 14px;
    color: white;
}

.blue {
    color: #2f5497 !important;
}

.y10 {
    margin: 10px 0;
}

.b10 {
    padding: 10px 0;
}

.section {
    margin: 20px 0 10px 0;
}

.medium {
    font-weight: 800;
    color: yellow;
}

.mision {
    background-color: #0b1f41;
    display: flex;
    flex-direction: column;
}

.containerInput {
    border: solid 1px red;
}